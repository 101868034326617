import React, { useState, useEffect ,useCallback} from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Row,
  Alert,
} from "react-bootstrap";
import { Shimmer } from "react-shimmer";
import Confirm from "../Confirm";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FilesCreate from "../File/FilesCreate";
import RelatedListFiles from "../File/RelatedListFiles";
import RelatedListProducts from "../Project/RelatedListProducts";
import CurrencyFormatter from "currency-formatter-react";
import Path from "../common/Path";
import moment from "moment";
import { LIST_TAB_TITLE } from "../../constants/CONSTANT";
import * as constants from "../../constants/CONSTANT";
import Form from "react-bootstrap/Form";
import PubSub from "pubsub-js";
import EmailComposer from "../common/EmailComposer";
import Chat from "../common/Chat";
import jwt_decode from "jwt-decode";
import Helmet from "react-helmet";
import Main from "../layout/Main";
import PaymentRelatedList from "./PaymentRelatedList";
import PaymentEdit from "./PaymentEdit";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { disableCursor } from "@fullcalendar/core/internal";
const LeadView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const course = location.state;
  const [lead, setLead] = useState(location.state ? location.state : {});
  console.log("lead view", lead);
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [relatedListFiles, setRelatedListFiles] = useState(true);
  const [relatedListLeadtTests, setRelatedListLeadtTests] = useState(true);

  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalShowPriceBook, setmodalShowPriceBook] = useState(false);
  const [modalShowEmail, setModalShowEmail] = useState(false);
  const [reletedListProducts, setreletedListProducts] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [SelectedUserId, setRoleId] = useState({});
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [refreshLocationList, setRefreshLocationList] = useState();
  const [selectedVisitType, setSelectedVisitType] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [files, setFiles] = useState([]);
  const [leadStatusArray, setleadStatusArray] = useState(
    constants.Repair_CATEGORY_VALUES
  );
  const [userInfo, setUserInfo] = useState({});
  const [locationDetails, setLocationDetails] = useState({
    latitude: null,
    longitude: null,
    address: '',
    record: {},
  });
  const [paymentsTab, setpaymentsTab] = useState(false);
  const [modalShowPayment, setModalShowPayment] = useState(false);
  const [relatedListPayment, setRelatedListPayment] = useState(false);
  const [receivedPayment, setReceivedPayment] = useState(0);
  const [refreshPaymentList, setRefreshPaymentList] = useState();

  const [modalShowLocation, setModalShowLocationHistory] = useState(false);
  const [relatedListLocation, setRelatedListLocationHistory] = useState(false);
  const [selectUser, setSelectUser] = useState({});
  const [option, setoption] = useState();
  const [contact, setContacts] = useState({});
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [remarks, setRemarks] = useState();
  const [payments, setPayments] = useState({});
  const [locationRecord, setLocationRecord] = useState({});
  const [currentrecord, setCurrentrecord] = useState();
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(null);
  const [address, setAddress] = useState();
  useEffect(() => {
    setUserInfo(jwt_decode(localStorage.getItem("token")));
    fetchLead();

    //init();
  }, []);

  const fetchLead = () => {
    if (
      !lead.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      lead.id = location.pathname.split("/")[2];
    }

    async function initStudent() {
      console.log("After Save", lead);
      console.log("After Save2", location.state);
      let result = await WhiteObjectApi.fetchRepairTicketById(lead.id);
      if(lead.contactid){
        let fetchContact = await WhiteObjectApi.fetchContactById(lead.contactid)

        if (fetchContact) {
          setContacts(fetchContact);
        } else {
          setContacts({});
        }
      }
      const fetchUser = await WhiteObjectApi.fetchUsers();
      const fetchPayment = await WhiteObjectApi.fetchProjectPayments(lead.id);
      ////console.log("fetchUser=>" + JSON.stringify(fetchUser))
      let usr = [];
      fetchUser.map((item) => {
        var obj = {};
        obj.value = item.id;
        obj.label = item.username;
        ////console.log(" obj.label >" + obj.label)
        usr.push(obj);
      });

      setSelectUser(usr);
      console.log("REESult", result);
      if (result) {
        setLead(result);
        setData(result);
      } else {
        setLead({});
      }
      if (fetchPayment) {
        setPayments(fetchPayment);
      } else {
        setPayments({});
      }
    }
    initStudent();
  };

  const deleteRepairTicket = async () => {
    console.log("Delertee", lead);
    const result = await WhiteObjectApi.deleteRepairTicket(location?.state.id);
    if (result.success) {
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Deleted ",
        message: "Record Deleted successfully",
      });
      navigate(`/projects`);
    }
    console.log("leadleadlead", lead.id);
  };
  const editLead = () => {
    navigate(`/repairprojects/${lead.id}/e`, { state: lead });
  };

  const submitCheckIn = () => {
    setModalShowLocationHistory(false);
    // setRefreshFileList(Date.now());
  };

  const handleSelect = (key) => {
    if (key === "products") {
      setreletedListProducts(true);
      setRelatedListFiles(false);
      setRelatedListPayment(false);
      setRelatedListLocationHistory(false);
    } else if (key === "files") {
      setRelatedListFiles(true);
      setreletedListProducts(false);
      setRelatedListPayment(false);
      setRelatedListLocationHistory(false);
    } else if (key === "payment") {
      setRelatedListFiles(false);
      // setReletedListTask(false);
      setreletedListProducts(false);
      setRelatedListPayment(true);
      setRelatedListLocationHistory(false);
    } else if (key === "location") {
      setRelatedListLocationHistory(true);
      setRelatedListFiles(false);
      setreletedListProducts(false);
      setRelatedListPayment(false);
    }
  };

  const submitFiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    setmodalShowPriceBook(false);
    fetchLead();
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    setFiles(selectedFiles);
    console.log("Selected Files:", selectedFiles);
  };

  const submitTasks = () => {
    setmodalShowPriceBook(false);
    setModalShowEmail(false);
    setRefreshTaskList(Date.now());
  };

  const handleRoleChange = (e) => {
    setoption(e);
    setRoleId({ ...files, parentid: e.value });
  };

 

  // Find the current status' sortorder
  //  const currentStatus = leadStatusArray.find(status => status.sortorder === lead.leadstatus);
  console.log("leadStatusArray", leadStatusArray);

  // Checking Lead Status array vallue and shwoing as till length as higlighted.
  const highlightedStatusArray = leadStatusArray.map((status) => ({
    ...status,
    highlight: status.sortorder <= lead.leadstatus,
  }));

  const submitpayments = () => {
    setModalShowPayment(false);
    setRefreshPaymentList(Date.now());
    fetchLead();
    // setModalShowTask(false);
  };
  const onPaymentListUpdate = (total) => {
    setReceivedPayment(total);
  };

  // ----- Check IN Check Out Logic starts here ----
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        // setAlert(null);
      }, 3000);
    }
  }, [alert]);

  
  const handleCheckIn = async () => {
  
    setModalShowLocationHistory(true);
  };

  const handleCheckOut = async () => {
    setModalShowLocationHistory(false);
  };

  const [currentRec, setCurrentRecord] = useState({});
  const [locationRecordv1, setLocationRecordv1] = useState({});
  const [latitudev1, setLatitudev1] = useState(null);
  const [longitudev1, setLongitudev1] = useState(null);
  const [addressv1, setAddressv1] = useState("");
  const [buttonDisabledv1, setButtonDisabledv1] = useState(false);
  const [remarksv1, setRemarksv1] = useState("");

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!window.google) {
        const googleMapScript = document.createElement("script");
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_GOOGLE_MAPS_API_KEY&callback=initMap`;
        googleMapScript.async = true;
        googleMapScript.defer = true;
        window.document.body.appendChild(googleMapScript);
      } else {
        window.initMap();
      }
    };

    window.initMap = () => {
      myMapFunction();
    };

    loadGoogleMapsScript();
  }, [latitudev1, longitudev1, addressv1]);

  const myMapFunction = async () => {
    if (lead.street && lead.city && lead.zipcode) {
      const address = `${lead.street}, ${lead.city}, ${lead.state} ${lead.zipcode}`;
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK") {
          const location = results[0].geometry.location;
          setLatitudev1(location.lat());
          setLongitudev1(location.lng());

          const mapProp = {
            center: location,
            zoom: 14,
          };

          const map = new window.google.maps.Map(
            document.getElementById("googleMapaddress"),
            mapProp
          );

          const marker = new window.google.maps.Marker({
            map,
            position: location,
            title: results[0].formatted_address,
          });

          const infowindow = new window.google.maps.InfoWindow({
            content: results[0].formatted_address,
          });

          marker.addListener("click", () => {
            infowindow.open(map, marker);
          });

          setAddressv1(results[0].formatted_address);
        } else {
          console.error(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    } else if (lead.location_link) {
      const geocoder = new window.google.maps.Geocoder();
      const url = new URL(lead.location_link);
      const latLng = url.pathname.split('/').pop().split(',');
      const lat = parseFloat(latLng[0]);
      const lng = parseFloat(latLng[1]);

      setLatitudev1(lat);
      setLongitudev1(lng);

      const location = new window.google.maps.LatLng(lat, lng);
      const mapProp = {
        center: location,
        zoom: 14,
      };

      const map = new window.google.maps.Map(
        document.getElementById("googleMapaddress"),
        mapProp
      );

      const marker = new window.google.maps.Marker({
        map,
        position: location,
        title: addressv1 ? addressv1 : lead.location_link,
      });

      const infowindow = new window.google.maps.InfoWindow({
        content: addressv1,
      });

      marker.addListener("click", () => {
        infowindow.open(map, marker);
      });
    }
  };



  // End here

  return (
    <Main> 
      <>
        <Helmet>
          <title>{LIST_TAB_TITLE.LeadEdit}</title>
        </Helmet>
      </>
      <Container>
        {lead && (
          <>
            {modalShow && (
              <Confirm
                show={modalShow}
                onHide={() => setModalShow(false)}
                deleteRepairTicket={deleteRepairTicket}
                title="Confirm delete?"
                message="You are going to delete the record. Are you sure?"
                table="repair"
              />
            )}
            {/* <Row className="view-form">
            <Col lg={11} className="pb-3 pt-2">
              <Link className="nav-link" to="/projects">
                Home <i className="fa-solid fa-chevron-right"></i>{" "}
                <div style={{ color: "#23468c", display: "inline" }}>Project</div>
              </Link>
            </Col>
            <Col></Col>
          </Row> */}

            <Row className="view-form d-flex p-2 mx-auto">
              <Col lg={8}>
                <Row className="view-form-header align-items-center">
                  <Col lg={8}>
                    Repair Ticket Name
                    <h5>{lead.firstname} {lead.lastname}</h5>
                  </Col>
                  <Col lg={4} className="d-flex justify-content-end">
                    <Button
                      className="btn-sm mx-2"
                      onClick={() => editLead(true)}
                    >
                      <i className="fa-regular fa-pen-to-square"></i>
                    </Button>
                    <Button
                      className="btn-sm mx-2"
                      variant="danger"
                      onClick={() => setModalShow(true)}
                    >
                      Delete
                    </Button>
                    {/* <Button
                      className="float-right btn-sm"
                      onClick={() => setModalShowEmail(true)}
                      variant="success"
                      title="Send Email"
                    >
                      <i class="fa-regular fa-envelope mx-2"></i>
                    </Button> */}


                    {/* <Button
                      className="ml-2"
                      disabled={!buttonDisabled}
                      variant="danger"
                      onClick={() => handleCheckOut()}
                      style={{ marginLeft: "6px" }}
                    >
                      Check-Out
                    </Button> */}
                  </Col>
                </Row>

                <Row className="py-3 ibs-edit-form">
                  {/* <Col lg={12}>
                  <Path
                    values={leadStatusArray}
                    selectedValue={lead.leadstatus}
                  />
                </Col> */}
                  <Col lg={12}>
                    <div className="scrollable-container">
                      <Path
                        values={highlightedStatusArray}
                        selectedValue={lead.repair_status}
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <label>Phone</label>
                    <span>{lead.phone}</span>
                  </Col>
                  <Col lg={6}>
                    <label>Qty of Window/Door</label>
                    <span>{lead.qty_windows}</span>
                  </Col>
                  <Col lg={6}>
                    <label>Invoice Number</label>
                    <span>{lead.invoice_number}</span>
                  </Col>
                  <Col lg={6}>
                    <label>Repair Status</label>
                    <span>{lead.repair_status}</span>
                  </Col>
                  <Col lg={6}>
                    <label>Site Visit Date</label>
                    <span>
                      {" "}
                      {lead.site_visit_date ? moment(lead.site_visit_date).format("DD MMM, yyyy") : ""}
                    </span>
                  </Col>
                  <Col lg={6}>
                    <label>Email</label>
                    <span>
                      {lead.email}
                    </span>
                  </Col>
                  

                  <Col lg={6}>
                    <label>Assigned User</label>
                    <span>
                      {/* Image Url for Production  */}
                      {/* <img src={`https://spark.indicrm.io/images/${userInfo.tenantcode}/users/${lead.ownerid}.thumbnail/${Math.random()}`} className="rounded-circle " style={{height: "20px", width: "20px", objectFit: "cover"}}/>   */}
                      {/* Image Url for Local  */}
                      <img
                        src="https://www.w3schools.com/bootstrap4/img_avatar3.png"
                        className="rounded-circle"
                        style={{
                          height: "30px",
                          width: "30px",
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                      {/* <img
                        src={localStorage.getItem("myimage")}
                        className="rounded-circle"
                        style={{ height: "30px" }}
                        alt=""
                      /> */}

                      <Badge
                        bg="warning"
                        className="mx-2"
                        style={{ display: "inline", color: "#000" }}
                      >
                        {lead.username}
                      </Badge>
                    </span>
                  </Col>
                  <Col lg={12}>
                    <label>Time Slot</label>
                    <span>{lead.time_slot}</span>
                  </Col>
                  <Col lg={12}>
                    <label>Type of Complaint</label>
                    <span>{lead.type_of_complaint}</span>
                  </Col>
                  <Col lg={12}>
                    <label>Specific Time</label>
                    <span>{lead.specific_time}</span>
                  </Col>
                  <Col lg={12}>
                    <label>Description</label>
                    <span>{lead.description}</span>
                  </Col>
                  <Col lg={12}>
                    <label>Created Date</label>
                    <span>
                      {moment(lead.createddate).format("DD MMM, yyyy")}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col lg={12} className="section-header">
                      ADDRESS INFORMATION
                    </Col>
                    <Col lg={6}>
                     
                      <label>Location Link</label>
                      <span>{lead.location_link}</span>
                      <a
                        href={lead.location_link ? lead.location_link : disableCursor}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Map
                      </a>
                    </Col>
                    <Col lg={6}>
                      <label>Complete Address</label>
                      <span>{lead.street}</span>
                    </Col>
                    <Col lg={6}>
                      <label>City</label>
                      <span>
                        {lead.city !== null ? lead.city : ""} {lead.zipcode}
                      </span>
                    </Col>
                    <Col lg={6}>
                      <label>State</label>
                      <span>{lead.state}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Country</label>
                      <span>{lead.country}</span>
                    </Col>
                    <Col lg={12}>
                      <div
                        id="googleMapaddress"
                        className="my-2"
                        style={{
                          width: "100%",
                          height: "300px",
                          border: "1px solid black",
                        }}
                      ></div>
                    </Col>
                    <Col lg={12} className="section-header">
                      CONTACT INFORMATION
                    </Col>
                    <Col lg={6}>
                      <label>Contact Name</label>
                      <span>{contact.firstname} {contact.lastname}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Contact Phone</label>
                      <span>{contact.phone}</span>
                    </Col>
              <Col lg={12} className="section-header">
                AMOUNT INFORMATION
              </Col>
              {/* <Col lg={6}>
                <label>Expected Project Amount</label>
                <span>{lead.expected_project_amount}</span>
              </Col>
              <Col lg={6}>
                <label>Sales Amount Project</label>
                <span>{lead.sales_amount_project}</span>
              </Col> */}
              <Col lg={6}>
                <label>Total Amount</label>
                <Badge
                  bg="secondary"
                  style={{
                    width: "30%",
                    fontWeight: "normal",
                    fontSize: "1rem",
                    textAlign: "left",
                  }}
                >
                  <CurrencyFormatter
                    value={lead.repair_total_amount ? lead.repair_total_amount : 0}
                    currency="INR"
                    thousandSeparator={false}
                  />{" "}
                </Badge>
              </Col>
              <Col lg={6}>
                <label>Received Amount</label>
                <Badge
                  bg="success"
                  style={{
                    width: "30%",
                    fontWeight: "normal",
                    fontSize: "1rem",
                    textAlign: "left",
                  }}
                >
                  <CurrencyFormatter
                    value={payments.paidamount || 0} 
                    
                    currency="INR"
                    thousandSeparator={false}
                  />{" "}
                </Badge>
              </Col>
              <Col lg={6}>
                <label>Pending Amount</label>

                <Badge
                  bg="danger"
                  style={{
                    width: "30%",
                  fontWeight: "normal",
                    fontSize: "1rem",
                    textAlign: "left",
                  }}
                >
                  <CurrencyFormatter
                    //  value={(lead.project_total_amount - (payments.paidamount || 0)) || 0} 
                     value={Math.max(0, lead.repair_total_amount - (payments.paidamount || 0)) || 0}
                    // value={0}
                    currency="INR"
                    thousandSeparator={false}
                  />{" "}
                </Badge>
              </Col>

              <Col></Col>
            </Row>

            <Card bg="light" text="light" className="mb-2 mt-4">
              <Card.Header className="d-flex justify-content-between">
                <Tabs
                  defaultActiveKey="files"
                  id="uncontrolled-tab-example"
                  onSelect={(key) => handleSelect(key)}
                >
                  {/* <Tab eventKey="patienttests" title="Medical Tests"></Tab> */}
                  <Tab eventKey="files" title="Files"></Tab>
                  {/* <Tab eventKey="tasks" title="Tasks"></Tab> */}
                  {/* <Tab eventKey="products" title="Products"></Tab> */}
                  <Tab eventKey="payment" title="Payment"></Tab>
                  {/* <Tab eventKey="location" title="Location History"></Tab> */}
                </Tabs>

                {relatedListFiles && (
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowFile(true)}
                  >
                    New File
                  </Button>
                )}

                {relatedListPayment && (
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowPayment(true)}
                  >
                    New Payment
                  </Button>
                )}

                {modalShowFile && (
                  <FilesCreate
                    show={modalShowFile}
                    onHide={() => setModalShowFile(false)}
                    parent={lead}
                    table="medicaltest"
                    submitFiles={submitFiles}
                  />
                )}
                {modalShowPayment && (
                  <PaymentEdit
                    show={modalShowPayment}
                    onHide={() => setModalShowPayment(false)}
                    parent={lead}
                    table="paymentTablel"
                    submitpayments={submitpayments}
                    pendingAmount=  {(lead.repair_total_amount - (payments.paidamount || 0)) } //Added by shivani
                  />
                )}
              </Card.Header>
              <Card.Body>
                {relatedListFiles && (
                  <RelatedListFiles
                    refreshFileList={refreshFileList}
                    parent={lead}
                    submitFiles={submitFiles} //Added by shivani
                  />
                )}
                {reletedListProducts && (
                  <RelatedListProducts
                    refreshTaskList={refreshTaskList}
                    parent={lead}
                  />
                  // ) : (
                  //   ""
                  //
                )}
                {relatedListPayment && (
                  <PaymentRelatedList
                    refreshPaymentList={refreshPaymentList}
                    parent={lead}
                    onPaymentListUpdate={onPaymentListUpdate}
                    parentsubmitpayments={submitpayments} // Shivani on 7 Aug
                    pendingAmount=  {(lead.repair_total_amount - (payments.paidamount || 0)) } //Added by shivani

                  />
                )}

              </Card.Body>
            </Card>

            {/* <EmailComposer
              size="lg"
              show={modalShowEmail}
              onHide={() => setModalShowEmail(false)}
              parentid={lead?.id}
              toEmail={lead?.email}
              table="lead"
              submitTasks={submitTasks}
            /> */}
          </>
        )}

{/*  */}
      </Container>
    </Main>
  );
};
export default LeadView;
