/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import moment from "moment";
import { useLocation } from "react-router-dom";
import PubSub from "pubsub-js";
import Select from "react-select";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import CityState from "../../constants/CityState.json";
import { LIST_TAB_TITLE } from "../../constants/CONSTANT";
import * as constants from "../../constants/CONSTANT";

import jwt_decode from "jwt-decode";
import Helmet from "react-helmet";
import Main from "../layout/Main";

const LeadEdit = () => {
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [optionContact, setOptionContact] = useState({});
  const [selectedContact, setSelectedContact] = useState({});
  const [selectedLeadSource, setSelectedLeadSource] = useState({});
  const [selectStatus, setSelectStatus] = useState({});
  const [selectIndustry, setSelectIndustry] = useState({});
  const [selectSalutation, setSalutation] = useState({});
  const [lostReason, setLostReason] = useState(false);
  const [selectUser, setSelectUser] = useState({});
  const [name, setName] = useState("");
  const [state, setState] = useState([]); //Added by shivani
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [option, setoption] = useState();
  const [leadStatusArray, setleadStatusArray] = useState(
    JSON.parse(localStorage.getItem("lead_status"))
  );
  const [showModal, setShowModal] = useState(false);
  const [disableUserAssginDrodown, setDisableUserAssginDrodown] = useState(false);
  const [showValidationMessage, setShowValidationMessage] = useState(false); //Added by shivani
  const [errors, setErrors] = useState({
    poc_contact_phone: "",
    whatsapp_no: "",
  }); //Added by shivani
  //const [rating,setRating]=useState([{}]);
  const industryValue = [
    { value: "", label: "--None--" },
    { value: "Agriculture", label: "Agriculture" },
    { value: "Apparel", label: "Apparel" },
    { value: "Banking", label: "Banking" },
    { value: "Chemicals", label: "Chemicals" },
    { value: "Communications", label: "Communications" },
  ];
  const salutationValue = [
    { value: "", label: "--None--" },
    { value: "Mr.", label: "Mr." },
    { value: "Ms.", label: "Ms." },
    { value: "Dr.", label: "Dr." },
    { value: "Mrs..", label: "Mrs.." },
    { value: "Prof.", label: "Prof.." },
  ];
  const roleValue = [
    { value: "", label: "--None--" },
    { value: "ADMIN", label: "Admin" },
    { value: "USER", label: "User" },
    // {value:"Dr.",label:"Dr."},
    // {value:"Mrs..",label:"Mrs.."},
    // {value:"Prof.",label:"Prof.."},
  ];
  const leadSource = [
    { value: "Web", label: "Web" },
    { value: "Phone Enquiry", label: "Phone Enquiry" },
    { value: "Partner Referral", label: "Partner Referral" },
    { value: "Purchased List", label: "Purchased List" },
    { value: "Other", label: "Other" },
  ];
  const leadStatus = leadStatusArray;
  const [show, setShow] = React.useState(false);
  const [lead, setLead] = useState(location.state ? location.state : {});

  // ////console.log(:)
  let userInfo;
  useEffect(() => {
    if (!lead.id) {
      setShowModal(true);
    }
    let userInfo = jwt_decode(localStorage.getItem("token"));


    if(userInfo.id === lead.ownerid){
      console.log('userInfo', userInfo);
      console.log("Edit Permission");
    }
    

    if (location?.state) {
      //Logic To Disable Field Assgin User on Edit Time.
      if(location?.state.leadstatus === 'Project'){
        setDisableUserAssginDrodown(true); 
      }
      //Logic End Here.
      // let salutation = salutationValue.filter(salutation => salutation.value === location.state.salutation)
      let source = leadSource.filter(
        (source) => source.value === location.state.leadsource
      );
      let industry = industryValue.filter(
        (industry) => industry.value === location.state.industry
      );
      let status = leadStatus.filter(
        (status) => status.value === location.state.leadstatus
      );

      ////console.log('source',leadStatus)
      // setSalutation(salutation[0]); Contact Name
      setSelectedLeadSource(source[0]);
      setSelectIndustry(industry[0]);
      setSelectStatus(status[0]);
      setLostReason(location.state.iswon === false);

      ////console.log('Lead Data ', location.state)
      if (lead.id) {
        let temp = {};
        temp.value = location.state.ownerid;
        temp.label = location.state.ownername;
        setoption(temp);
      } else {
        let temp = {};
        temp.value = userInfo.id;
        temp.label = userInfo.username;
        setoption(temp);
        lead.ownername = userInfo.username;
        lead.ownerid = userInfo.id;
      }
    } else {
      let temp = {};
      temp.value = userInfo.id;
      temp.label = userInfo.username;
      setoption(temp);
      lead.ownername = userInfo.username;
      lead.ownerid = userInfo.id;
    }

    async function init() {
      const fetchUser = await WhiteObjectApi.fetchUsers();
      ////console.log("fetchUser=>" + JSON.stringify(fetchUser))
      let usr = [];
      fetchUser.map((item) => {
        var obj = {};
        obj.value = item.id;
        obj.label = item.username;
        ////console.log(" obj.label >" + obj.label)
        usr.push(obj);
      });
      setSelectUser(usr);

      const fetchContacts = await WhiteObjectApi.fetchContact();
      if (fetchContacts) {
        let contactOptions = fetchContacts.map((item) => ({
          value: item.id,
          label: item.firstname + " " + item.lastname,
          contact: item,
        }));
        setOptionContact(contactOptions);

        if (location?.state) {
          let acc = contactOptions.filter(
            (acc) => acc.value === location.state.contactid
          );
          // console.log("acc", acc);
          setSelectedContact(acc);
        }
      } else {
        setOptionContact({});
      }

      let st = [];
      CityState.forEach((item) => {
        let obj = {
          value: item.state,
          label: item.state,
        };
        st.push(obj);
      });

      // Remove duplicates using a Set
      const uniqueStates = [...new Set(st.map((item) => item.value))];

      // Create an array of objects with unique values
      const uniqueObjects = uniqueStates.map((state) => ({
        value: state,
        label: state,
      }));

      // Sort the array in ascending order
      uniqueObjects.sort((a, b) => a.label.localeCompare(b.label));

      // Set the state
      setState(uniqueObjects);
      //console.log('st:', st);
      setState(st);
    }

    init();
  }, []);

  //Cheking User Permission form this Useffect.
  // useEffect(() => {
  //   try {
  //     if (localStorage.getItem("token")) {
  //       let user = getUserDetails();
  //       setUserInfo(user);

  //       var perm = user.permissions
  //         .map(function (obj) {
  //           return obj.name;
  //         })
  //         .join(";");
  //       setPermissions(perm);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  const handleState = (e) => {
    setCities([]);
    lead.city = ""; //Added by shivani bug 56 1 july
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === e.value) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
    });
    filteredCities.sort((a, b) => a.label.localeCompare(b.label)); //Added By Shivani
    setCities(filteredCities);
    setLead({ ...lead, state: e.value });
  };

  const handleSelectListChange = (value, name) => {
    ////console.log('contact:', lead);
    setLead({ ...lead, [name]: value.value });
    setSelectedCity(value.value);
  };
  //Access all the value input values
  // Started by shivani
  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";

    // Validate phone and WhatsApp numbers
    if (name === "poc_contact_phone" || name === "whatsapp_no") {
        if (!/^\d*$/.test(value)) {
            error = "Only numbers are allowed";
        } else if (value.length > 10) {
            error = "Phone number cannot exceed 10 digits";
        }
    }

    // Set the error if any and return early if validation fails
    if (error) {
        setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
        return;
    } else {
        setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
    }

    setLead(prevLead => {
        const updatedLead = { ...prevLead, [name]: value };

        // Disable user assign dropdown if leadstatus is 'Project'
        if (updatedLead.leadstatus === 'Project') {
          setDisableUserAssginDrodown(true);
        } else {
          setDisableUserAssginDrodown(false);
        }

        return updatedLead;
    });

    // Show/hide lost reason based on lead status
    if (name === "leadstatus") {
        const selectedStatus = leadStatus.find(status => status.label === value);
        setLostReason(selectedStatus?.is_lost || false);
    }
};

  // const handleChange = (e) => {
  //   let temp = { ...lead };

  //   const { name, value } = e.target;
  //   let error = "";

  //   if (name === "poc_contact_phone" || name === "whatsapp_no") {
  //     if (!/^\d*$/.test(value)) {
  //       error = "Only numbers are allowed";
  //     } else if (value.length > 10) {
  //       error = "Phone number cannot exceed 10 digits";
  //     }
  //   }

  //   setErrors({ ...errors, [name]: error });

  //   if ((name === "poc_contact_phone" || name === "whatsapp_no") && error) {
  //     return;
  //   }

  //   console.log("temp.leadstatus",temp.leadstatus)

  //   if(temp.leadstatus === 'Project'){
  //     setDisableUserAssginDrodown(true);
  //   }else{
  //     setDisableUserAssginDrodown(false);
  //   }

  //   setLead({ ...lead, [name]: value });
   

  //   if (name === "leadstatus") {
  //     leadStatus.map((status) => {
  //       if (status.label === value) {
  //         if (status.is_lost === true) {
  //           setLostReason(true);
  //         } else {
  //           setLostReason(false);
  //         }
  //         return;
  //       }
  //     });
  //   }
  // };
  // End by shivani

  const handleRoleChange = (e) => {
    ////console.log('event', e)
    setoption(e);
    setLead({ ...lead, ownerid: e.value, ownername: e.label });
  };

  //Contact Handler Change
  const handleContact = (e) => {
    console.log("Event on Contact", e.contact);
    setSelectedContact(e);
    const selectedContactDetails = e.contact;
    if (!lead.id) {
      //   let st = [];
      //   CityState.map((item) => {
      //     var obj = {};
      //     obj.value = selectedContactDetails.state;
      //     obj.label = selectedContactDetails.state;
      //     //////console.log(" obj.label >"+ obj.label)
      //     st.push(obj);
      //   });
      //   let finalStates = {};
      //   st = st.filter(function (currentObject) {
      //     if (currentObject.value in finalStates) {
      //       return false;
      //     } else {
      //       finalStates[currentObject.value] = true;
      //       return true;
      //     }
      //   });
      //   console.log("st:", st);
      //   setState(st);
      console.log("Staate =>", state);
      setLead({
        ...lead,
        contactid: e.value,
        salutation: selectedContactDetails.salutation,
        firstname: selectedContactDetails.firstname,
        lastname: selectedContactDetails.lastname,
        phone: selectedContactDetails.phone,
        email: selectedContactDetails.email,
        street: selectedContactDetails.street,
        city: selectedContactDetails.city,
        state: selectedContactDetails.state,
        country: selectedContactDetails.country,
        zipcode: selectedContactDetails.pincode,
        whatsapp_no: selectedContactDetails.whatsapp_no,
        location_link: selectedContact.location_link,
      });
      console.log("Salutation Event", selectedContactDetails.salutation);
    } else {
      setLead({ ...lead, contactid: e.value });
    }
  };

  console.log("Lead Data on Contact Select", lead);
  // const handleSubmit = async (e) => {
  //   console.log("handle submit call", lead);
  //   e.preventDefault();

  //   if (checkRequredFields()) {
  //     console.log("yes")
  //     setValidated(true);
  //     return;
  //   }

  //   // if (errors.poc_contact_phone && errors.whatsapp_no) {
  //   //   return;
  //   // }

  //   //========= Logic to perform Create or Edit ======
  //   let result = {};
  //   let resultContact = {};
  //   let iswon = null;
  //   let archiveRecord = {};
  //   console.log("lead.:", lead);
  //   // debugger;
  //   // return false;
  //   leadStatus.map((status) => {
  //     //console.log('status:', status);
  //     if (status.label === lead.leadstatus) {
  //       if (status.is_converted === true) {
  //         iswon = true;
  //       } else if (status.is_lost === true) {
  //         iswon = false;
  //       }
  //     }
  //   });

  //   lead.iswon = iswon;

  //   if (lead.id) {
  //     //console.log("lead.id: ", lead);
  //     ////console.log("lead: ", JSON.stringify(lead));
  //     if (lead.leadstatus === "Archived") {
  //       lead.isarchive = true;
  //     }
  //     result = await WhiteObjectApi.saveLead(lead);

  //     if (lead.leadstatus === "Archived") {
  //       lead.lead_id = lead.id;
  //       lead.contact_id = lead.contactid;
  //       // lead.ownerid  = lead.ownerid
  //       archiveRecord = await WhiteObjectApi.createArchive(lead);
  //     }
  //     ////console.log("result ===>", result);
  //     if (result.success) {
  //       PubSub.publish("RECORD_SAVED_TOAST", {
  //         title: "Record Saved",
  //         message: "Record saved successfully",
  //       });
  //       navigate(`/leads/${lead.id}`, { state: lead });
  //     }
  //   } else {
  //     console.log("Lead Object for Save", lead);
  //     lead.isarchive = false;
  //     result = await WhiteObjectApi.createLead(lead);
  //     if (result.errors) {
  //       PubSub.publish('RECORD_ERROR_TOAST', { title: 'Record Save Error', message: result.errors });
  //     }

  //     // if (result) {
  //       // lead.lead_id = result.id;
  //     //   // resultContact = await WhiteObjectApi.createContact(lead);
  //     // }
  //     ////console.log('result', result)
  //     if (result) {
  //       PubSub.publish("RECORD_SAVED_TOAST", {
  //         title: "Record Saved",
  //         message: "Record saved successfully",
  //       });
        
  //     }

  //     if (result.errors) {
  //       console.log("Error Log Excute",result.error);
  //       PubSub.publish('RECORD_ERROR_TOAST', { title: 'Record Save Error', message: result.errors });
  //     }else{
  //       console.log("Success Log Excute");
  //       PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record created successfully' });
  //       navigate(`/leads/${result.id}`, { state: result });
  //     }
  //   }
  // };


  const handleSubmit = async (e) => {
    console.log("handle submit call", lead);
    e.preventDefault();
  
    // Check for required fields
    if (checkRequredFields()) {
      console.log("Required fields missing");
      setValidated(true);
      return;
    }
  
    let result = {};
    let archiveRecord = {};
    let iswon = null;
  
    // Determine if lead is won or lost based on status
    const selectedStatus = leadStatus.find(status => status.label === lead.leadstatus);
    if (selectedStatus) {
      iswon = selectedStatus.is_converted ? true : selectedStatus.is_lost ? false : null;
    }
    lead.iswon = iswon;
  
    try {
      // If lead exists, update it
      if (lead.id) {
        console.log("Updating lead with ID:", lead.id);
        if (lead.leadstatus === "Archived") {
          lead.isarchive = true;
        }
  
        result = await WhiteObjectApi.saveLead(lead);
  
        // Handle archiving if lead is set to archived status
        if (lead.leadstatus === "Archived") {
          lead.lead_id = lead.id;
          lead.contact_id = lead.contactid;
          archiveRecord = await WhiteObjectApi.createArchive(lead);
        }
  
        if (result.success) {
          PubSub.publish("RECORD_SAVED_TOAST", {
            title: "Record Saved",
            message: "Record updated successfully",
          });
          navigate(`/leads/${lead.id}`, { state: lead });
        }
      } else {
        // Creating a new lead
        console.log("Creating new lead", lead);
        lead.isarchive = false;
        result = await WhiteObjectApi.createLead(lead);
  
        if (result.errors) {
          throw new Error(result.errors);
        } else {
          PubSub.publish('RECORD_SAVED_TOAST', {
            title: 'Record Saved',
            message: 'Record created successfully',
          });
          navigate(`/leads/${result.id}`, { state: result });
        }
      }
    } catch (error) {
      console.error("Error while saving lead:", error);
      PubSub.publish('RECORD_ERROR_TOAST', {
        title: 'Record Save Error',
        message: error.message || 'An error occurred while saving the lead',
      });
    }
  };
  

  //Close Modal Function
  // Added by shivani start
  const handleCloseModal = () => {
    if (selectedContact.value) {
      setShowModal(false);
    } else {
      setShowValidationMessage(true);
    }
  };

  const handleCloseModalContact = () => {
    setShowModal(false);
  };

  const checkRequredFields = () => {
    let isValid = true; // Assume all fields are valid initially
  
    // Option validation (if applicable to your form)
    if (!option) {
      setShow(true);
      setShowValidationMessage(true);
      document.querySelector(".username").style.border = "1px solid red";
      isValid = false; // Mark as invalid if `option` is missing
    }
  
    // Validate required fields
    if (!lead.firstname || lead.firstname.trim() === "") {
      isValid = false;
      console.log("First name is required");
    }
  
    if (!lead.lastname || lead.lastname.trim() === "") {
      isValid = false;
      console.log("Last name is required");
    }
  
    if (!lead.phone || lead.phone.trim() === "") {
      isValid = false;
      console.log("Phone number is required");
    }
  
    if (!lead.leadstatus || lead.leadstatus.trim() === "") {
      isValid = false;
      console.log("Lead status is required");
    }
  
    if (!lead.leadsource || lead.leadsource.trim() === "") {
      isValid = false;
      console.log("Lead source is required");
    }
  
    if (!lead.state || lead.state.trim() === "") {
      isValid = false;
      console.log("State is required");
    }
  
    if (!lead.city || lead.city.trim() === "") {
      isValid = false;
      console.log("City is required");
    }
  
    if (!lead.street || lead.street.trim() === "") {
      isValid = false;
      console.log("Street is required");
    }
  
    if (!lead.zipcode || lead.zipcode.trim() === "") {
      isValid = false;
      console.log("Zipcode is required");
    }
  
    if (!lead.country || lead.country.trim() === "") {
      isValid = false;
      console.log("Country is required");
    }
  
    // Show validation message if any field is invalid
    setShowValidationMessage(!isValid);
  
    // Return true if any required field is missing (i.e., validation fails)
    return !isValid;
  };
  
  // Added by shivani end
  const handleCancel = () => {
    navigate("/leads/", { state: lead });
  };

  console.log("Leads Data", lead);
  return (
    <div>
      {/* <>
        <Helmet>
          <title>{LIST_TAB_TITLE.LeadEdit}</title>
        </Helmet>
      </> */}
      <Container className="view-form">
        <Row className="view-form">
          <Col></Col>
          <Col lg={8} className="pb-1 pt-2">
            <Link className="nav-link" to="/leads">
              Home <i className="fa-solid fa-chevron-right"></i>{" "}
              <div style={{ color: "#23468c", display: "inline" }}>Leads</div>
            </Link>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col></Col>
          <Col lg={8}>
            <Form
              className="mt-3"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <Row className="view-form-header align-items-center">
                <Col lg={9}>
                  {name === "" ? <h6>Create Lead</h6> : <h6> Edit Lead</h6>}

                  <h5>{name}</h5>
                </Col>
                <Col lg={3} style={{ textAlign: "right" }}>
                  <Button className="btn-sm mx-2" onClick={handleSubmit}>
                    Save
                  </Button>
                  <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              <Row className="ibs-edit-form mb-4" lg={12}>
                <Alert
                  variant="danger"
                  show={show}
                  className="error-alert my-3 mx-2"
                >
                  Please Select Assign Staff
                </Alert>
                <Row>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Contact Name
                      </Form.Label>
                      <Select
                        placeholder="Contact Name... "
                        onChange={handleContact}
                        options={optionContact}
                        value={selectedContact}
                        // isDisabled={!lead.id}
                        className="custom-select username" // Added by shivani
                      ></Select>
                      {/* Added by shivani start i july */}
                      {/* {showValidationMessage && !selectedContact.value && (
                        <div className="invalid-feedback d-block">
                          Please provide a contact name
                        </div>
                      )} */}
                      {/* added by shivani ends 1july */}
                    </Form.Group>
                  </Col>
                </Row>

                <Col lg={2}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Salutation
                    </Form.Label>
                    <Form.Select
                      aria-label="Select Salutation"
                      name="salutation"
                      value={lead.salutation}
                      onChange={handleChange}
                    >
                      <option value="">---Select---</option>
                      <option value="Mr.">Mr.</option>
                      <option value="Mrs.">Mrs.</option>
                      <option value="Miss">Miss</option>
                      <option value="Ms.">Ms.</option>
                      <option value="Dr.">Dr.</option>
                      <option value="Prof.">Prof.</option>
                      <option value="Architect.">Architect.</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      First Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="firstname"
                      required
                      placeholder="Enter First Name"
                      value={lead.firstname}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide First Name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicLastName"
                    >
                      Last Name
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="lastname"
                      placeholder="Enter LastName"
                      value={lead.lastname}
                      //defaultValue="Ali"
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter LastName.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Phone
                    </Form.Label>
                    <Form.Control
                      type="text" //Added by shivani
                      name="phone"
                      required
                      placeholder="Enter Phone"
                      value={lead.phone}
                      //defaultValue={['Asif']}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value) && value.length <= 10) {
                          handleChange(e); // Added by shivani
                        }
                      }}
                      maxLength={10} //Added by shivani
                      pattern="\d{10}" //Added by shivani
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide Phone.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Whatsapp No
                    </Form.Label>
                    <Form.Control
                      type="text" //Added by shivani
                      name="whatsapp_no"
                      placeholder="Enter whatsapp No"
                      value={lead.whatsapp_no}
                      //defaultValue={['Asif']}
                      onChange={(e) => handleChange(e)} //Added by shivani
                      maxLength={10} //Added by shivani
                      pattern="\d{10}" //Added by shivani
                    />
                    {/* //Added by shivani */}
                    {errors.whatsapp_no && (
                      <div className="invalid-feedback d-block">
                        {errors.whatsapp_no}
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Company
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="company"
                      placeholder="Enter Company"
                      value={lead.company}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Company.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Email
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Enter Email"
                      value={lead.email}
                      onChange={(e) => handleChange(e)}
                    />
                    {/* //Added by shivani */}
                    <Form.Control.Feedback type="invalid">
                      Please provide a email
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Title
                    </Form.Label>
                    {/* <Form.Control

                                        type="text"
                                        name="title"
                                        placeholder="Enter Title"
                                        value={lead.title}
                                        onChange={(e) => handleChange(e)}
                                    /> */}
                    {/* Added by shivani required tag*/}
                    <Form.Select
                      value={lead.title}
                      name="title"
                      onChange={handleChange}
                    >
                      <option value="">--Select--</option>
                      <option value="CEO">CEO</option>
                      <option value="Director">Director</option>
                      <option value="Manager">Manager</option>
                      <option value="Owner">Owner</option>
                      <option value="Partner">Partner</option>
                      <option value="Executive">Executive</option>
                    </Form.Select>
                    {/* Added by shivani */}
                    <Form.Control.Feedback type="invalid">
                      Please provide a Title
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Website
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="websit"
                      placeholder="Enter Website"
                      value={lead.websit}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Fax.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicLeadSource"
                    >
                      Source
                    </Form.Label>
                    {/* Added by shivani Required attribute */}
                    <Form.Select
                      required
                      aria-label="Enter status"
                      value={lead.leadsource}
                      name="leadsource"
                      onChange={handleChange}
                    >
                      <option value="">--Select-Source--</option>
                      <option value="Phone">Phone</option>
                      <option value="Partner Referral">Partner Referral</option>
                      <option value="BNI">BNI</option>
                      <option value="Purchased List">Purchased List</option>
                      <option value="Web">Web</option>
                      <option value="Email">Email</option>
                      <option value="Whatsapp">Whatsapp</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Salesforce">Salesforce</option>
                      <option value="Friends & Relatives">Friends & Relatives</option>
                      <option value="Architect">Architect</option>
                      <option value="Old Client">Old Client</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                    {/* ADDed by shivani */}
                    <Form.Control.Feedback type="invalid">
                      Please provide a source
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Assigned User
                    </Form.Label>
                    <Select
                      required
                      value={option}
                      className="custom-select username"
                      onChange={(e) => handleRoleChange(e)}
                      // isDisabled={disableUserAssginDrodown}
                      options={selectUser}

                      //value={selectSalutation}
                    ></Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide Select-Role.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                {/* <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Payment Model
                                    </Form.Label>
                                    <Form.Select aria-label="Enter Status" name="paymentmodel" onChange={handleChange} value={lead.paymentmodel}>
                                        <option value="">--Select Payment Model--</option>
                                        
                                            <option value="Subscription">
                                                Subscription
                                            </option>
                                            <option value="One Time">
                                                One Time
                                            </option>
                                       


                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Payment Terms
                                    </Form.Label>
                                    <Form.Select aria-label="Enter Status" name="paymentterms" onChange={handleChange} value={lead.paymentterms}>
                                        <option value="">--Select Terms--</option>
                                        <option value="12">
                                                12 Months
                                            </option>
                                            <option value="24">
                                                24 Months
                                            </option>
                                            <option value="One Time">
                                                One Time
                                            </option>
                                            <option value="One Time with Yearly Renewal">
                                                One Time with Yearly Renewal
                                            </option>


                                    </Form.Select>
                                </Form.Group>
                            </Col> */}
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFees"
                    >
                      Status
                    </Form.Label>
                    <Form.Select
                      required
                      aria-label="Enter Status"
                      name="leadstatus"
                      onChange={handleChange}
                      value={lead.leadstatus}
                    >
                      <option value="">--Select-Status--</option>
                      {leadStatusArray.map((item, index) => (
                        <option value={item.label} key={index}>
                          {item.label}
                        </option>
                      ))}
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                      Please provide status.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {/* <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Expected Amount (₹)
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="amount"
                                        placeholder="Enter Expected Amount"
                                        value={lead.amount}
                                        onChange={(e) => handleChange(e)}
                                    />


                                    <Form.Control.Feedback type="invalid">
                                        Enter LeadStatus.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col> */}
                {lostReason && (
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFees"
                      >
                        Lost Reason
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="lostreason"
                        required
                        placeholder="Enter lost reason"
                        value={lead.lostreason}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                )}
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFees"
                    >
                      Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      placeholder="Enter Description"
                      value={lead.description}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={12} className="section-header">
                  POINT OF CONTACT INFORMATION
                </Col>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Contact Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="poc_contact_name"
                      placeholder="Enter Point of Contact Name"
                      value={lead.poc_contact_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Point of Contact Phone
                    </Form.Label>
                    <Form.Control
                      type="text" //Added by shivani
                      name="poc_contact_phone"
                      placeholder="Enter Point of Contact Phone"
                      value={lead.poc_contact_phone}
                      onChange={(e) => handleChange(e)}
                      maxLength={10} //Added by shivani
                      pattern="\d{10}" //Added by shivani
                    />
                    {/* //Added by shivani       */}
                    {errors.poc_contact_phone && (
                      <div className="invalid-feedback d-block">
                        {errors.poc_contact_phone}
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={12} className="section-header">
                  ADDRESS INFORMATION
                </Col>
                <Col lg={6} className="mt-1">
                  <Form.Group controlId="formBasicPhone">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Location Link
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="location_link"
                      placeholder="Enter Location Link"
                      value={lead.location_link}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter location_link.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      State
                    </Form.Label>
                    <Select
                      placeholder="State"
                      value={state.find(
                        (option) => option.value === lead.state
                      )} // Added by shivani
                      onChange={handleState}
                      options={state}
                      className="custom-select username" // Added by shivani
                    ></Select>
                    {/* Added by shivani start */}
                    {showValidationMessage && !lead.state && (
                      <div className="invalid-feedback d-block">
                        Please provide a state
                      </div>
                    )}
                    {/* added by shivani ends */}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      City
                    </Form.Label>
                    <Select
                      options={cities}
                      placeholder="Enter City"
                      onChange={(e) => {
                        handleSelectListChange(e, "city");
                      }}
                      name="city"
                      value={
                        cities.find((option) => option.value === lead.city) || {
                          label: lead.city,
                          value: lead.city,
                        }
                      } // Added by shivani
                      className="custom-select username" // Added by shivani
                    />
                    {/* Added by shivani start */}
                    {showValidationMessage && !lead.city && (
                      <div className="invalid-feedback d-block">
                        Please provide a city
                      </div>
                    )}
                    {/* added by shivani ends */}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Complete Address
                    </Form.Label>
                    <Form.Control
                      required //Added by shivani
                      type="text"
                      name="street"
                      placeholder="Enter Street"
                      value={lead.street}
                      onChange={(e) => handleChange(e)}
                    />
                    {/* Added by shivani */}
                    <Form.Control.Feedback type="invalid">
                      Please provide a street.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Zip / PostalCode
                    </Form.Label>
                    <Form.Control
                      required //Added by shivani
                      type="text"
                      name="zipcode"
                      placeholder="Enter PostalCode"
                      value={lead.zipcode}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a PostalCode.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} className="pb-3">
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Country
                    </Form.Label>
                    <Form.Control
                      required //Added by shivani
                      type="text"
                      name="country"
                      placeholder="Enter Country"
                      value={lead.country}
                      onChange={(e) => handleChange(e)}
                    />
                    {/* Added by shivani */}
                    <Form.Control.Feedback type="invalid">
                      Please provide a country.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col className="mt-5"></Col>
              </Row>
            </Form>
          </Col>
          <Col></Col>
        </Row>
        {/* On Create Time this Modal will Poup As per Client Requiment */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header>
            <Modal.Title>Select Contact User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Add your form fields for creating a new user here */}
            <Form>
              <Col lg={12} className="">
                <Form.Group className="mx-3">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Contact Name
                  </Form.Label>
                  <Select
                    className="custom-select username" //Added by shivani
                    placeholder="Contact Name... "
                    onChange={handleContact}
                    options={optionContact}
                    value={selectedContact}
                  ></Select>
                </Form.Group>
              </Col>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModalContact}>
              Close
            </Button>
            <Button variant="primary" onClick={handleCloseModal}>
              Next
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};
export default LeadEdit;
