import React from 'react';
import './ProgressTracker.css';

const steps = [
  { label: "Quote Sent" },
  { label : "Quote Rejected"},
  { label: "Quote Approved" },
  { label: "Advance Received" },
  { label: "Transfer To Production" },
  { label : "Production InProgress"},
  { label: "Production Completed" },
  { label : "Part Dispatched"},
  { label: "Dispatched" },
  { label: "Installation Started" },
  { label: "Installation Completed (Gets a Signed Slip)" },
  { label: "On-Hold" },
  { label: "Payment Received" },
  { label: "Project Complete" }
];

const ProgressTracker = ({ currentStep }) => {
  return (
    <div className="progress-container">
      {currentStep === 11 && (
        <div className="sparkles">
          <div className="sparkle" style={{ top: "10px", left: "20px" }}></div>
          <div className="sparkle" style={{ top: "20px", left: "30px" }}></div>
          <div className="sparkle" style={{ top: "30px", left: "40px" }}></div>
          <div className="sparkle" style={{ top: "40px", left: "50px" }}></div>
        </div>
      )}
      {steps.map((step, index) => (
        <div className="progress-step" key={index}>
          <div className={`progress-circle ${index === currentStep ? 'active' : index < currentStep ? 'completed' : ''}`}>
            <span className="step-number">
              {index < currentStep ? '✔' : index + 1}
            </span>
          </div>
          <div className="progress-label">
            {step.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgressTracker;
