import React, { useState, useEffect, useCallback } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Row,
  Alert,
} from "react-bootstrap";
import { Shimmer } from "react-shimmer";
import Confirm from "../Confirm";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FilesCreate from "../File/FilesCreate";
import RelatedListFiles from "../File/RelatedListFiles";
import RelatedListProducts from "../Project/RelatedListProducts";
import CurrencyFormatter from "currency-formatter-react";
import Path from "../common/Path";
import moment from "moment";
import { LIST_TAB_TITLE } from "../../constants/CONSTANT";
import * as constants from "../../constants/CONSTANT";
import Form from "react-bootstrap/Form";
import PubSub from "pubsub-js";
import EmailComposer from "../common/EmailComposer";
import Chat from "../common/Chat";
import jwt_decode from "jwt-decode";
import Helmet from "react-helmet";
import Main from "../layout/Main";
import PaymentRelatedList from "./PaymentRelatedList";
import PaymentEdit from "./PaymentEdit";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import RelatedLocationHistory from "../LocationHistory/RelatedLocationHistory";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import UserTracking from "./UserTracking";
import Permissionhelper from "../common/Permissionhelper";

const LeadView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const course = location.state;
  const [lead, setLead] = useState(location.state ? location.state : {});
  console.log("lead view", lead);
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [relatedListFiles, setRelatedListFiles] = useState(true);
  const [relatedListLeadtTests, setRelatedListLeadtTests] = useState(true);
  const [showArchive, setArchiveShow] = useState(false);
  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalShowPriceBook, setmodalShowPriceBook] = useState(false);
  const [modalShowEmail, setModalShowEmail] = useState(false);
  const [reletedListProducts, setreletedListProducts] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [SelectedUserId, setRoleId] = useState({});
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [refreshLocationList, setRefreshLocationList] = useState();
  const [selectedVisitType, setSelectedVisitType] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [files, setFiles] = useState([]);
  const [leadStatusArray, setleadStatusArray] = useState(
    constants.Project_Stage_Status
  );
  const [userInfo, setUserInfo] = useState({});
  const [locationDetails, setLocationDetails] = useState({
    latitude: null,
    longitude: null,
    address: "",
    record: {},
  });
  const [paymentsTab, setpaymentsTab] = useState(false);
  const [modalShowPayment, setModalShowPayment] = useState(false);
  const [relatedListPayment, setRelatedListPayment] = useState(false);
  const [receivedPayment, setReceivedPayment] = useState(0);
  const [refreshPaymentList, setRefreshPaymentList] = useState();

  const [modalShowLocation, setModalShowLocationHistory] = useState(false);
  const [relatedListLocation, setRelatedListLocationHistory] = useState(false);
  const [selectUser, setSelectUser] = useState({});
  const [option, setoption] = useState();

  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [remarks, setRemarks] = useState();
  const [payments, setPayments] = useState({});
  const [locationRecord, setLocationRecord] = useState({});
  const [currentrecord, setCurrentrecord] = useState();
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(null);
  const [address, setAddress] = useState();

  const [showDetails, setShowDetails] = useState(true);
  const [showNotesMessages, setShowNotesMessages] = useState(false);
  useEffect(() => {
    setUserInfo(jwt_decode(localStorage.getItem("token")));
    fetchLead();
    // setShowDetails(true);
    //init();
  }, []);

  const fetchLead = () => {
    if (
      !lead.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      lead.id = location.pathname.split("/")[2];
    }

    async function initStudent() {
      console.log("After Save", lead);
      console.log("After Save2", location.state);
      let result = await WhiteObjectApi.fetchProjectById(lead.id);
      // let payment = await WhiteObjectApi.fetchProjectPayments(lead.id)
      const fetchUser = await WhiteObjectApi.fetchUsers();
      const fetchPayment = await WhiteObjectApi.fetchProjectPayments(lead.id);
      ////console.log("fetchUser=>" + JSON.stringify(fetchUser))
      let usr = [];
      fetchUser.map((item) => {
        var obj = {};
        obj.value = item.id;
        obj.label = item.username;
        ////console.log(" obj.label >" + obj.label)
        usr.push(obj);
      });

      setSelectUser(usr);
      console.log("REESult", result);
      if (result) {
        setLead(result);
        setData(result);
      } else {
        setLead({});
      }
      if (fetchPayment) {
        setPayments(fetchPayment);
      } else {
        setPayments({});
      }
    }
    initStudent();
  };

  const deleteProject = async () => {
    console.log("Delertee", lead);
    const result = await WhiteObjectApi.deleteProject(location?.state.id);
    if (result.success) {
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Deleted ",
        message: "Record Deleted successfully",
      });
      navigate(`/projects`);
    }
    console.log("leadleadlead", lead.id);
  };
  const editLead = () => {
    navigate(`/projects/${lead.id}/e`, { state: lead });
  };

  const submitCheckIn = () => {
    setModalShowLocationHistory(false);
    // setRefreshFileList(Date.now());
  };

  const handleSelect = (key) => {
    if (key === "products") {
      setreletedListProducts(true);
      setRelatedListFiles(false);
      setRelatedListPayment(false);
      setRelatedListLocationHistory(false);
    } else if (key === "files") {
      setRelatedListFiles(true);
      setreletedListProducts(false);
      setRelatedListPayment(false);
      setRelatedListLocationHistory(false);
    } else if (key === "payment") {
      setRelatedListFiles(false);
      // setReletedListTask(false);
      setreletedListProducts(false);
      setRelatedListPayment(true);
      setRelatedListLocationHistory(false);
    } else if (key === "location") {
      setRelatedListLocationHistory(true);
      setRelatedListFiles(false);
      setreletedListProducts(false);
      setRelatedListPayment(false);
    }
  };

  const handleSelectTab = (key) => {
    if (key === "details") {
      // setReletedListProjects(true);
      setShowDetails(true);
      setShowNotesMessages(false);
    } else if (key === "notesMessages") {
      setShowDetails(false);
      setShowNotesMessages(true);
    }
  };

  const submitFiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    setmodalShowPriceBook(false);
    fetchLead(); //Added By shivani
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    setFiles(selectedFiles);
    console.log("Selected Files:", selectedFiles);
  };

  const submitTasks = () => {
    setmodalShowPriceBook(false);
    setModalShowEmail(false);
    setRefreshTaskList(Date.now());
  };

  const handleRoleChange = (e) => {
    setoption(e);
    setRoleId({ ...files, parentid: e.value });
  };

  // Find the current status' sortorder
  //  const currentStatus = leadStatusArray.find(status => status.sortorder === lead.leadstatus);
  console.log("leadStatusArray", leadStatusArray);

  // Checking Lead Status array vallue and shwoing as till length as higlighted.
  const highlightedStatusArray = leadStatusArray.map((status) => ({
    ...status,
    highlight: status.sortorder <= lead.leadstatus,
  }));

  const submitpayments = () => {
    setModalShowPayment(false);
    setRefreshPaymentList(Date.now());
    // setModalShowTask(false);
    fetchLead(); //Added by shivani
  };
  const onPaymentListUpdate = (total) => {
    setReceivedPayment(total);
  };

  // ----- Check IN Check Out Logic starts here ----
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        // setAlert(null);
      }, 3000);
    }
  }, [alert]);

  const handleCheckIn = async () => {
    setModalShowLocationHistory(true);
  };

  const handleCheckOut = async () => {
    setModalShowLocationHistory(false);
  };

  // Added by shivani start
  const formatAmount = (value) => {
    if (isNaN(value)) {
      return "0";
    }
    const num = parseFloat(value);
    return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  // added by shivani End here
  // End here
  
  //Archive Work Added by Farhan Khan on 04-Oct-2024
  const archiveLeads = async () => {
    let obj = {};
    obj.isarchive = true;
    obj.id = lead.id;
    const achieveLead = await WhiteObjectApi.saveProject(obj);
    if (achieveLead) {
      lead.project_id = lead.id;
      lead.project_lead_id = lead.lead_id;
      lead.project_contact_id = lead.contact_id;
      lead.lead_id = null;
      const result = await WhiteObjectApi.createArchive(lead);
      if (result.success) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Archive ",
          message: "Record Archive successfully",
        });
      }

      navigate(`/projects`);
    }
  };

  return (
    <div>
      <>
        <Helmet>
          <title>{LIST_TAB_TITLE.LeadEdit}</title>
        </Helmet>
      </>
      <Container>
        {lead && (
          <>
            {modalShow && (
              <Confirm
                show={modalShow}
                onHide={() => setModalShow(false)}
                deleteLead={deleteProject}
                title="Confirm delete?"
                message="You are going to delete the record. Are you sure?"
                table="lead"
              />
            )}
            {/* <Row className="view-form">
            <Col lg={11} className="pb-3 pt-2">
              <Link className="nav-link" to="/projects">
                Home <i className="fa-solid fa-chevron-right"></i>{" "}
                <div style={{ color: "#23468c", display: "inline" }}>Project</div>
              </Link>
            </Col>
            <Col></Col>
          </Row> */}

            <Row className="view-form d-flex p-2 mx-auto">
              <Col lg={8}>
                <Row
                  className="view-form-header align-items-center"
                  style={{ width: "155%" }}
                >
                  <Col lg={8}>
                    Project
                    <h5>{lead.project_name}</h5>
                  </Col>
                  <Col lg={4} className="d-flex justify-content-end">
                    <Button
                      className="btn-sm mx-2"
                      onClick={() => editLead(true)}
                    >
                      <i className="fa-regular fa-pen-to-square"></i>
                    </Button>
                    {Permissionhelper.checkPermission(
                      constants.SUPER_ADMIN
                    ) && (
                      <Button
                        className="btn-sm"
                        variant="danger"
                        onClick={() => setModalShow(true)}
                      >
                        Delete
                      </Button>
                    )}
                    {/* <Button
                      className="float-right btn-sm"
                      onClick={() => setModalShowEmail(true)}
                      variant="success"
                      title="Send Email"
                    >
                      <i class="fa-regular fa-envelope mx-2"></i>
                    </Button> */}

                    <Button
                      disabled={buttonDisabled}
                      variant="success"
                      className="mx-2"
                      onClick={() => handleCheckIn()}
                    >
                      Check-In
                    </Button>

                    {/* <Button
                      className="ml-2"
                      disabled={!buttonDisabled}
                      variant="danger"
                      onClick={() => handleCheckOut()}
                      style={{ marginLeft: "6px" }}
                    >
                      Check-Out
                    </Button> */}
                    <Button
                        className="btn-sm mx-2"
                        variant="warning"
                        onClick={() => setArchiveShow(true)}
                      > 
                        Archive Project
                      </Button>
                  </Col>
                </Row>
                </Col>
                <Card style={{ width: "154%" }} className="mt-2">
                  <Col lg={12} className="mt-4" style={{ color: "#fff" }}>
                    <Tabs
                      defaultActiveKey="details"
                      id="uncontrolled-tab-example"
                      onSelect={(key) => handleSelectTab(key)}
                      style={{ marginBottom: "12px" }}
                    >
                      <Tab eventKey="details" title="Details"></Tab>
                      <Tab eventKey="notesMessages" title="Notes & Messages"
                      ></Tab>
                    </Tabs>
                  </Col>
                </Card>
                {showDetails && (
                  <Row className="py-3 ibs-edit-form" style={{ width: "155%" }}>
                    {/* <Col lg={12}>
                  <Path
                    values={leadStatusArray}
                    selectedValue={lead.leadstatus}
                  />
                </Col> */}
                    <Col lg={12}>
                      <div className="scrollable-container">
                        <Path
                          values={highlightedStatusArray}
                          selectedValue={lead.project_status}
                        />
                      </div>
                    </Col>

                    <Col lg={6}>
                      <label>Lead Name</label>
                      <span>{lead.leadname}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Project Code</label>
                      <span>{lead.project_code}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Contact Name</label>
                      <span>{lead.contactname}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Project Status</label>
                      <span>{lead.project_status}</span>
                    </Col>

                    <Col lg={6}>
                      <label>Invoice Number</label>
                      <span>{lead.invoice_number}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Expected Delivery Date</label>
                      <span>
                        {" "}
                        {lead.expected_date_delivery
                          ? moment(lead.expected_date_delivery).format(
                              "DD MMM, yyyy"
                            )
                          : ""}
                      </span>
                    </Col>
                    <Col lg={6}>
                      <label>Quote Approval Date</label>
                      <span>
                        {lead.quote_approval_date
                          ? moment(lead.quote_approval_date).format(
                              "DD MMM, yyyy"
                            )
                          : ""}
                      </span>
                    </Col>

                    <Col lg={6}>
                      <label>Assigned User</label>
                      <span>
                        {/* Image Url for Production  */}
                        {/* <img src={`https://spark.indicrm.io/images/${userInfo.tenantcode}/users/${lead.ownerid}.thumbnail/${Math.random()}`} className="rounded-circle " style={{height: "20px", width: "20px", objectFit: "cover"}}/>   */}
                        {/* Image Url for Local  */}
                        <img
                          src="https://www.w3schools.com/bootstrap4/img_avatar3.png"
                          className="rounded-circle"
                          style={{
                            height: "30px",
                            width: "30px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                        <img
                          src={localStorage.getItem("myimage")}
                          className="rounded-circle"
                          style={{ height: "30px" }}
                          alt=""
                        />

                        <Badge
                          bg="warning"
                          className="mx-2"
                          style={{ display: "inline", color: "#000" }}
                        >
                          {lead.username}
                        </Badge>
                      </span>
                    </Col>
                    {/* <Col lg={12}>
                    <label>Description</label>
                    <span>{lead.description}</span>
                  </Col> */}
                    <Col lg={12}>
                      <label>Created Date</label>
                      <span>
                        {moment(lead.createddate).format("DD MMM, yyyy")}
                      </span>
                    </Col>

                    <Col lg={12} className="section-header">
                      AMOUNT INFORMATION
                    </Col>
                    {/* <Col lg={6}>
                <label>Expected Project Amount</label>
                <span>{lead.expected_project_amount}</span>
              </Col>
              <Col lg={6}>
                <label>Sales Amount Project</label>
                <span>{lead.sales_amount_project}</span>
              </Col> */}
                    <Col lg={6}>
                      <label>Total Amount</label>
                      <Badge
                        bg="secondary"
                        style={{
                          width: "30%",
                          fontWeight: "normal",
                          fontSize: "1rem",
                          textAlign: "left",
                        }}
                      >
                        {/* {'₹ ' + formatAmount(lead?.project_total_amount)}{" "}   Added by shivani */}
                        {"₹ " +
                          formatAmount(
                            lead?.project_total_amount || 0 //Added by shivani
                          )}{" "}
                      </Badge>
                    </Col>
                    <Col lg={6}>
                      <label>Received Amount</label>
                      <Badge
                        bg="success"
                        style={{
                          width: "30%",
                          fontWeight: "normal",
                          fontSize: "1rem",
                          textAlign: "left",
                        }}
                      >
                        {/* <CurrencyFormatter
                    value={payments.paidamount || 0} 
                    
                    currency="INR"
                    thousandSeparator={false}
                  />{" "} */}
                        {/* {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    Math.max(
                      0,
                      lead.project_total_amount - (payments.paidamount || 0)
                    )
                  )} */}
                        {"₹ " +
                          formatAmount(
                            payments?.paidamount || 0 //Added by shivani
                          )}
                      </Badge>
                    </Col>

                    <Col lg={6}>
                      <label>Tax Amount</label>
                      <Badge
                        bg="info"
                        style={{
                          width: "30%",
                          fontWeight: "normal",
                          fontSize: "1rem",
                          textAlign: "left",
                        }}
                      >
                        {/* <CurrencyFormatter
                    value={lead.tax_amount || 0}
                    currency="INR"
                    thousandSeparator={false}
                  />{" "} */}

                        {"₹ " +
                          formatAmount(
                            lead?.tax_amount || 0 //Added by shivani
                          )}
                      </Badge>
                    </Col>
                    <Col lg={6}>
                      <label>Pending Amount</label>

                      <Badge
                        bg="danger"
                        style={{
                          width: "30%",
                          fontWeight: "normal",
                          fontSize: "1rem",
                          textAlign: "left",
                        }}
                      >
                        {/* <CurrencyFormatter
                    //  value={(lead.project_total_amount - (payments.paidamount || 0)) || 0}
                    value={
                      Math.max(
                        0,
                        lead.project_total_amount - (payments.paidamount || 0)
                      ) || 0
                    }
                    currency="INR"
                    thousandSeparator={false}
                  />{" "} */}
                        {"₹ " +
                          formatAmount(
                            Math.max(
                              0,
                              lead.project_total_amount -
                                (payments.paidamount || 0)
                            )
                          )}
                      </Badge>
                    </Col>
                    <Col lg={6}>
                      <label>Expense Amount</label>
                      <Badge
                        bg="primary"
                        style={{
                          width: "30%",
                          fontWeight: "normal",
                          fontSize: "1rem",
                          textAlign: "left",
                        }}
                      >
                        {/* <CurrencyFormatter
                    value={lead.expense_amount || 0}
                    currency="INR"
                    thousandSeparator={false}
                  />{" "} */}
                        {"₹ " +
                          formatAmount(
                            lead?.expense_amount || 0 //Added by shivani
                          )}
                      </Badge>
                    </Col>
                  </Row>
                  // </Col>
                )}
              

              {showNotesMessages && (
                <Card style={{ width: "154%" }} className="mt-2">
                  <Row className="mt-4">
                    <Col lg={12}>
                      <Chat parentid={lead.id} />
                    </Col>
                  </Row>
                </Card>
              )}
              {/* </Col> */}
              <Col></Col>
            </Row>

            <Card bg="light" text="light" className="mb-2 mt-4">
              <Card.Header className="d-flex justify-content-between">
                <Tabs
                  defaultActiveKey="files"
                  id="uncontrolled-tab-example"
                  onSelect={(key) => handleSelect(key)}
                >
                  {/* <Tab eventKey="patienttests" title="Medical Tests"></Tab> */}
                  <Tab eventKey="files" title="Files"></Tab>
                  {/* <Tab eventKey="tasks" title="Tasks"></Tab> */}
                  <Tab eventKey="products" title="Products"></Tab>
                  <Tab eventKey="payment" title="Payment"></Tab>
                  <Tab eventKey="location" title="Location History"></Tab>
                </Tabs>

                {relatedListFiles && (
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowFile(true)}
                  >
                    New File
                  </Button>
                )}

                {/* Added by shivani start */}
                {relatedListPayment && (
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowPayment(true)}
                  >
                    New Payment
                  </Button>
                )}
                {/* Added by shivani end*/}

                {/* Archive Functionlity */}


                {showArchive && (
                  <Confirm
                    show={showArchive}
                    onHide={() => setArchiveShow(false)}
                    archiveLeads={archiveLeads}
                    title="Confirm Archive?"
                    message="You are going to Archive the record. Are you sure?"
                    table="archive"
                  />
                  )}

                {modalShowFile && (
                  <FilesCreate
                    show={modalShowFile}
                    onHide={() => setModalShowFile(false)}
                    parent={lead}
                    table="medicaltest"
                    submitFiles={submitFiles}
                  />
                )}
                {modalShowPayment && (
                  <PaymentEdit
                    show={modalShowPayment}
                    onHide={() => setModalShowPayment(false)}
                    parent={lead}
                    table="paymentTablel"
                    submitpayments={submitpayments} //Added by shivani 7 aug */
                    pendingAmount={
                      lead.project_total_amount - (payments.paidamount || 0)
                    } //Added by shivani 7 aug */
                    // pendingAmount=  {(lead.project_total_amount - (payments.paidamount || 0)) } //Added by shivani
                  />
                )}
              </Card.Header>
              <Card.Body>
                {relatedListFiles && (
                  <RelatedListFiles
                    refreshFileList={refreshFileList}
                    parent={lead}
                    submitFiles={submitFiles}
                  />
                )}
                {reletedListProducts && (
                  <RelatedListProducts
                    refreshTaskList={refreshTaskList}
                    parent={lead}
                  />
                  // ) : (
                  //   ""
                  //
                )}
                {relatedListLocation && (
                  <RelatedLocationHistory
                    refreshTaskList={refreshLocationList}
                    parent={lead}
                  />
                  // ) : (
                  //   ""
                  // )}
                )}
                {relatedListPayment && (
                  <PaymentRelatedList
                    refreshPaymentList={refreshPaymentList}
                    parent={lead}
                    onPaymentListUpdate={onPaymentListUpdate}
                    parentsubmitpayments={submitpayments} //Added by shivani 7 aug
                    pendingAmount={
                      lead.project_total_amount - (payments.paidamount || 0)
                    } //Added by shivani 7 aug
                  />
                )}

                {modalShowLocation && (
                  <UserTracking
                    show={modalShowLocation}
                    onHide={() => setModalShowLocationHistory(false)}
                    parent={lead}
                    table="location"
                    submitCheckIn={submitCheckIn}
                  />
                )}
              </Card.Body>
            </Card>

            {/* <EmailComposer
              size="lg"
              show={modalShowEmail}
              onHide={() => setModalShowEmail(false)}
              parentid={lead?.id}
              toEmail={lead?.email}
              table="lead"
              submitTasks={submitTasks}
            /> */}
          </>
        )}

        {/*  */}
      </Container>
    </div>
  );
};
export default LeadView;
